/* global */
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

.apexcharts-legend-marker {
    background-color: red !important;
}
* {
    font-family: Manrope !important;
}
body {
    margin: 0 !important;
    font-family: Manrope !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-wrap: break-word;
}
button {
    border: none;
}
.asterisk {
    color: #ff0000;
}
.header-main-logo {
    width: 100px;
    height: 40px;
    object-fit: contain;
}

.header-main-logo-container .header-main-logo {
    height: 100%;
}
.f-12 {
    font-size: 12px;
}
.f-l6 {
    font-size: 16px;
}
.f-sem-bol {
    font-weight: 500;
}
.f-bol {
    font-weight: 700;
}
.f-24 {
    font-size: 24px;
}

.fw-5 {
    font-weight: 500;
}
.f-8 {
    font-size: 8px;
}
.gap-10 {
    gap: 10px;
}
.text-underline {
    text-decoration: underline;
}
.text-grey {
    color: #8780ab;
}
.fw-8 {
    font-weight: 800;
}
.fw-6 {
    font-weight: 500;
}
.fw-7 {
    font-weight: 700;
}
.blue-text {
    color: #0044cc !important;
}
input:focus-visible {
    outline: none;
}
.d-center {
    display: flex !important;
    align-items: center;
    justify-content: center;
}
/* portal */
body,
html {
    background: rgb(46, 46, 65);
}
p {
    margin-bottom: 0.3rem !important;
}
.PhoneInput {
    position: relative;
}
.hidden {
    display: none;
}
.PhoneInput .PhoneInputCountry {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
}
.PhoneInputInput {
    padding-left: 50px;
}
.main-page-container {
    background: #12b6bc;
    border-radius: 26px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 1030px;
    width: 80%;
}
.main-page-container label {
    line-height: 21.86px;
}
.main-page-container .row:not(.main-page-container.instructions .row) {
    margin: 0px !important;
}
.main-page-container .portal_left_section {
    padding: 20px;
    height: 100%;
}
.main-page-container .portal_right_section {
    padding: 15px;
    background-color: #fff;
    height: auto;
    border-radius: 26px;
}
.main-page-container .portal_left_section .title {
    color: #fff;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 115.1%;
}
.main-page-container .portal_right_section .title {
    color: #0b0b0b;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    margin-bottom: 0;
}
.main-page-container .incbot {
    float: right;
    right: -30px;
    position: relative;
}
.main-page-container .portal_right_section .sub-title {
    font-size: 20px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.15px;
    color: #13161bf2;
}
.main-page-container .portal_right_section .title-line {
    height: 1px;
    background-color: #2d3440;
}
.main-page-container .portal_right_section .content-container {
    height: calc(100% - 75px);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.main-page-container .portal_right_section .verify-content-container {
    height: calc(100% - 130px);
}
.instruction_text {
    font-size: 12px;
    font-weight: 500;
    line-height: 16.63px;
    color: #000000;
}
.upload-type {
    color: #8780ab;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
/* Hide the actual input */
.hidden-input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

/* Style for the label */
.file-label {
    cursor: pointer;
    display: inline-block;
    padding: 8px 16px;
    width: 100%;
    color: #133f7d;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.form-control:focus {
    box-shadow: none !important;
    border-color: #cacaca;
}
.feedback-wrapper-new {
    background-color: #f5f5f5;
    z-index: 1;
    margin: 0 auto;
    align-items: center;
    position: absolute;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 512px;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.feeback-collection {
    /* position: absolute; */
    max-width: 512px;
    width: 100%;
    height: auto;
    background: #ffffff;
    padding: 20px;
}

.feeback-collection-header {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: #000000;
}

.star-btn {
    padding: 10px 10px 10px 0px;
    background: initial;
}

.feedback-input {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    width: 100%;
    height: 80px;
    margin: 10px 0px;
}

.feedback-submit {
    letter-spacing: 0.05em;
    color: #ffffff;
    background: #0092dc;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 132%;
    display: flex;
    padding: 5px 10px;
}

.feedback-submit.disabled {
    background-color: rgba(98, 98, 98, 1) !important;
}

.feedback-inner-head {
    align-items: center;
    color: #000000;
}

.feedback-inner-div {
    /* padding: 10px; */
    margin-top: 20px;
}

.feedback-close-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    display: block;
}
/* portal end */

/* responsive end */

.upload-type {
    color: #8780ab;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

/* Hide the actual input */
.hidden-input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

/* Style for the label */
.gap-50 {
    gap: 50px;
}
.file-label {
    cursor: pointer;
    display: inline-block;
    padding: 8px 16px;
    width: 100%;
    color: #133f7d;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.footer-icons {
    border-radius: 5px;
    border: 1px solid #2d3440;
    width: 30px !important;
    height: 30px !important;
}
.footer-icons svg {
    width: 20px !important;
    height: 20px !important;
}

.time-info {
    color: #e9e9e9;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1px;
}
.red-color {
    color: #f2464b;
}
.white-color {
    color: #e9e9e9;
}
.time-info-timer {
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1px;
}
.question-title {
    overflow: hidden;
    color: rgba(245, 249, 255, 0.95);
    text-align: center;
    text-overflow: ellipsis;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.25px;
    width: 50%;
}
.question-titlev2 {
    overflow: hidden;
    color: rgba(245, 249, 255, 0.95);
    text-align: center;
    text-overflow: ellipsis;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.25px;
    width: 50%;
}
.question-recording {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.25px;
}
.question-info-div {
    margin-top: -30px;
    margin-bottom: 8px;
}

.org-position-container {
    margin-bottom: 15px;
    margin-top: 10px;
}

.org-position-container .org-position {
    white-space: nowrap;
    max-width: 130px;
}

.end-btn-container {
    margin-top: 20px;
    position: relative;
    z-index: 100;
}
.strong-network {
    color: #37f28d;
}
.medium-network {
    color: #fac919;
}
.poor-network {
    color: #ed4c5a;
}
.preview-join-button {
    border-radius: 5px;
    border: 1px solid #12b6bc !important;
    background: #12b6bc !important;
    background-color: #12b6bc !important;
    color: rgba(255, 255, 255, 0.98);
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    width: 82px;
}
.start-interview {
    font-size: 12px !important;
}
.preview-page-layout {
    width: 1130px;
    max-width: 1130px;
    z-index: 100;
}
.dark-bg {
    background-color: rgba(19, 22, 27, 1);
}
.gif {
    width: 28px;
}
.main-view-line {
    height: 1px;
    margin-top: 5px;
    background-color: #fff;
}
.main-view-logo {
    margin-top: 20px;
    height: 65.5px;
    width: 160px;
    object-fit: contain;
    object-position: left center;
}
.timer-div {
    width: 300px;
}
.recording-view {
    font-size: 18px;
    font-weight: 500;
    margin-left: 6px;
}
.gifImage {
    bottom: 0px;
    height: 150px;
    margin: 0px auto;
    position: absolute;
    right: 0px;
    width: 220px;
}
.question-number {
    color: rgba(232, 170, 78, 0.95);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
}
.ask-question {
    color: rgba(245, 249, 255, 0.95);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.25px;
}
/* ProgressBar.css */
.progress-question-bar {
    display: flex;
    align-items: center;
}
.progress-circle span {
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
}
.progress-circle {
    width: 30px;
    height: 30px;
    position: relative;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    text-align: center;
}
.progress-circle:last-child {
    margin-right: 0;
}
.progress-circle.active {
    background-color: blue;
    color: white;
}
.progress-question-bar .filled {
    color: #191924;
    background-color: #f6f7fb;
    font-weight: 500;
    border: 1px solid #fff;
}
.progress-question-bar .not-filled {
    color: #fff;
    background-color: #2e2e41;
    font-weight: 500;
    border: 1px solid #fff;
}
.progress-question-bar .active {
    color: #fff;
    background-color: #2e2e41;
    font-weight: 700;
    border: 4px solid #fff;
}
.progress-line {
    color: #fff !important;
    width: 30px;
    border: 1px solid #fff !important;
    margin: 0px !important;
    opacity: 1 !important;
}
.question-info-container {
    padding: 1.5rem;
    padding-bottom: 0px;
}
.finish-interview-popup {
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    overflow-y: hidden;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 99vw;
    margin-left: 10px;
    z-index: 10000;
}

.finish-interview-popup-content {
    position: absolute;
    background-color: rgba(19, 22, 27, 1);
    border-radius: 8px;
    max-width: 500px;
}
.finish-interview-popup.acknowledge .finish-interview-popup-content {
    max-width: 560px;
    width: 95%;
    display: flex;
    justify-content: center;
}
.finish-logo {
    width: 1.5em;
}
.finish-title {
    font-size: 28px;
    line-height: 24px;
    font-weight: 600;
    color: rgba(245, 249, 255, 0.95);
    text-align: center;
}
.finish-title-end {
    font-size: 28px;
    line-height: 24px;
    font-weight: 600;
    color: rgba(245, 249, 255, 0.95);
    text-align: center;
}
.finish-title-m {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: rgba(245, 249, 255, 0.95);
    text-align: center;
}
.finish-title-main {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: rgba(224, 236, 255, 0.8);
    text-align: center;
}
.popper-title-m {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: rgba(245, 249, 255, 0.95);
    text-align: center;
}
.finish-interview-popup.acknowledge .finish-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-align: center;
    color: rgba(245, 249, 255, 0.95);
}
.acknowledge_title_line {
    height: 1px;
    width: 100%;
    background-color: rgba(94, 98, 103, 1);
}
.finish-interview-popup.acknowledge .acknowledge_text {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(245, 249, 255, 0.95);
}
.acknowledge_text-m {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: center;
    color: rgba(245, 249, 255, 0.95);
}
.finish-interview-popup.acknowledge .acknowledge_text_transcript {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgb(89 172 209);
}
.acknowledge_text_transcript_new {
    width: 100%;
    height: 100%;
    /* height: 150px; */
    overflow-y: scroll;
    padding: 18px 17px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgb(89 172 209);
    background-color: #191924;
}
.acknowledge_text_transcript_disable_new {
    width: 100%;
    height: 150px;
    overflow-y: scroll;
    padding: 18px 17px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(245, 249, 255, 0.2);
    background-color: #191924;
}

.popper_message_div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 30px 10px;
}
.gap-20 {
    gap: 20px;
}
.gap-30 {
    gap: 30px;
}
.finish-subtitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 21.78px;
    letter-spacing: 0.25px;
    color: rgba(195, 208, 229, 0.5);
}
.common-btn.cancel {
    background-color: transparent;
    border: 1px solid rgba(107, 125, 153, 1);
}
.end-call-btn {
    padding: 0px !important;
    height: auto !important;
}
.end-call {
    height: 30px;
}
.verification-otp-div div {
    justify-content: space-evenly;
}
.mounted {
    background-color: rgba(0, 0, 0, 0.7) !important;
}
.acknowledge_validation_text {
    color: #12b6bc;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.15px;
}

.acknowledge_validation_div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 30px 10px;
}

.interview-preview-page {
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    background-color: rgba(25, 25, 36, 1);
    position: fixed;
    top: 0;
}

.cancel {
    background-color: transparent !important;
    border: 1px solid rgba(107, 125, 153, 1) !important;
}

.question-timer-div .common-btn {
    min-width: 145px;
}

.question-timer-div-main {
    width: 270px;
}
.question-timer-div {
    margin: 0 auto;
}
.disabled-text {
    color: rgba(98, 98, 98, 1) !important;
}
.instruction_container {
    height: 90%;
}
.recording-video {
    margin: 20px 10px;
}

.face-detection-modal-content {
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    color: #f5f9fff2;
    margin-top: 10px;
    width: 98%;
}

.face-detection-modal-content-text1 {
    margin-bottom: 15px;
    padding: 0 60px;
}

.face-detection-modal-content-text2 {
    padding: 0 5px;
}
.visibility-hidden {
    visibility: hidden;
}
.mobile-next-popup {
    width: 100vw;
    height: 100vh;
    background-color: #00000085;
    position: absolute;
    z-index: 5000;
    justify-content: center;
    align-items: center;
}
.mobile-next-popup-inner {
    width: 319px;
    background-color: #2e2e41;
    color: rgba(245, 249, 255, 1);
    padding: 20px 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.mobile-next-popup-content {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
}
.mobile-next-popup-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.audio-video-check-main-container {
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    position: relative;
    background-color: #191924;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.audio-video-check-sub-container-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgba(245, 249, 255, 0.95);
    font-weight: 500;
}
.audio-video-check-sub-container-2 {
    max-width: 560px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.audio-video-check-sub-container-3 {
    max-width: 490px;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #13161b;
}
.acknowledge-text-m {
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 25px 15px;
    color: rgba(245, 249, 255, 0.95);
    line-height: 20px;
}
.transcript-container {
    width: 90%;
    height: 115px;
    background-color: #191924;
    padding: 18px 17px;
    overflow-y: scroll;
    font-size: 12px;
    color: rgb(89 172 209);
}
.transcript-text-disable {
    color: rgba(245, 249, 255, 0.2);
}
.line-margin {
    margin-top: 15px;
    margin-bottom: 15px;
}
.start-transcript-check-btn {
    border-radius: 5px;
    border: 1px solid #12b6bc !important;
    background: #12b6bc !important;
    background-color: #12b6bc !important;
    color: rgba(255, 255, 255, 0.98);
    text-align: center;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    width: 120px;
}
.start-transcript-check-btn-disabled {
    border-radius: 5px;
    border: 1px solid #9b9b9b !important;
    background: #9b9b9b !important;
    background-color: #9b9b9b !important;
    color: #ffffff !important;
    text-align: center;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    width: 120px;
    cursor: auto !important;
}
.mobile-start-box {
    width: 155.48px !important;
}
.getting-started {
    font-size: 16px;
    padding-top: 15px;
}
.header-image {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 74px;
    height: 38px;
    object-fit: contain;
}
.getting-started-sub {
    font-size: 12px;
    color: rgba(245, 249, 255, 0.8);
}
.sample-read-text {
    text-align: center;
    font-size: 12px;
    line-height: 24px;
    color: rgba(245, 249, 255, 0.95);
    padding: 10px 30px;
}
.form-logo {
    height: 52.5px;
    width: 128px;
    object-fit: contain;
    object-position: left center;
}
.header-components-logo {
    height: 70.5px;
    width: 172px;
    object-fit: contain;
}
.common-btn {
    background-color: #12b6bc;
    border-radius: 5px;
    max-width: 155.48px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 35px;
    color: #fff;
    border: 0.5px solid #0000001c;
}
.common-btn.disabled,
.common-btn:disabled {
    background-color: #9b9b9b !important;
}
/* .get-started-wrapper {
    margin-top: 4em;
} */
/* responsive  start*/
@media (max-width: 768px) {
    .video_container {
        height: 60% !important;
    }
    .question-info-container-m {
        padding: 0px;
    }
    .finish-subtitle {
        font-size: 14px;
    }
    .finish-title-end {
        font-size: 20px;
    }
    .finish-logo {
        font-size: 12px;
    }
    .face-detection-logo {
        display: none;
    }
    .face-detection-modal-content {
        padding: 15px;
    }
}

@media (max-width: 991px) {
    body,
    html {
        /* background: #191924; */
        background: rgb(46, 46, 65);
    }

    .ask-question code {
        font-size: 14px !important;
        font-weight: 300 !important;
    }

    .main-page-container .portal_right_section .content-container {
        height: auto;
        display: block !important;
    }

    .main-page-container {
        background: transparent;
        height: 100%;
        /* max-width: unset; */
        width: 100%;
        /* overflow: scroll; */
    }

    .mw-lg-100 {
        max-width: 100%;
    }

    .common-btn.disabled {
        background-color: rgba(98, 98, 98, 1) !important;
    }

    .form-control {
        color: #fff !important;
    }

    .main-page-container .row.portal_container {
        position: relative;
        margin-top: 0px !important;
        display: flex;
        height: 540px;
        align-items: center;
    }

    .main-page-container.instructions .row.portal_container {
        margin-top: 0px !important;
        max-height: calc(100% - 60px);
    }

    .portal_container .portal_right_section {
        border-radius: 16px;
        max-width: 475px;
        width: 100%;
        margin: 0 auto;
        background-color: rgba(30, 35, 42, 1);
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.05);
        margin-top: -20px;
    }

    /* .main-page-container.instructions .portal_container .portal_right_section {
        height: 100%;
        overflow: scroll;
    } */

    .main-page-container .row {
        margin: 0 1rem !important;
    }

    .main-page-container .portal_right_section .verify-content-container {
        height: 100%;
    }

    .portal_container .portal_right_section .title {
        font-size: 20px;
        font-weight: 500;
        color: #f5f9fff2;
    }

    .portal_container .portal_right_section .sub-title {
        font-size: 14px;
        line-height: 18px;
        color: #f5f9fff2;
    }

    .form-container label {
        color: #ffffff;
        font-size: 12px;
    }

    .common-btn {
        height: 40px;
        line-height: 24px;
        font-size: 14px;
    }

    .instruction_info {
        color: #fff;
        font-size: 11px;
    }

    .instruction_info .info-text {
        color: #ff822e;
    }

    .time-info-timer {
        font-size: 23px !important;
    }

    .question-recording {
        font-size: 12px !important;
    }

    .question-title {
        font-size: 12px !important;
    }
    .question-titlev2 {
        font-size: 12px !important;
    }
    .timer-div {
        width: 240px;
    }
    .question-timer-div .common-btn {
        min-width: 115px;
    }

    .header-main-logo-container {
        height: 40px;
        margin: 1rem !important;
    }
}
@media (max-width: 768px) {
    .finish-interview-popup {
        width: 95vw;
        overflow: auto;
        margin-top: 56px;
        height: calc(100% - 60px);
    }
    .finish-interview-popup.acknowledge .w-md-75 {
        width: 75% !important;
    }
    .gap-md-10 {
        gap: 10px;
    }
    .acknowledge_validation_div {
        display: inherit !important;
    }
    .finish-interview-popup.acknowledge .finish-interview-popup-content {
        overflow: auto;
        max-height: 80%;
    }
    .preview-page-layout {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .main-page-container .portal_right_section .verify-content-container.instruction_mob_content_container {
        overflow-y: auto;
        height: calc(100vh - 410px);
        max-height: 605px;
    }
    .popper_message_div {
        margin: 10px 10px;
    }
    .video_question_container {
        height: calc(100% - 250px) !important;
        display: block !important;
    }
}
@media (max-width: 575px) {
    .question-info-div.gap-50 {
        gap: 15px;
    }
    .ask-question {
        font-size: 10px;
    }
}
@media (max-width: 1024px) and (min-height: 800px) {
    /* .main-page-container.instructions .row.portal_container{
        margin-top: -40px !important;
    } */
    .main-page-container.instructions .portal_container .portal_right_section {
        height: auto;
    }
}
.recording-video {
    margin: 20px 10px;
}
.ask-question code {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.hms-ui-c-gypLyU {
    z-index: 1000111 !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
.verification-otp-div input {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    box-shadow: none !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}
.width-text-color {
    color: #ffffff;
}
.new-mt {
    margin-top: 10px;
}
.sidebar-container {
    margin: 0.62rem 1.5rem 0.7rem 0.0625rem;
    border: 0.5px solid #fff;
    width: 42vw;
    background-color: #2e2e41;
}
.sidebar-container-mcq {
    width: 100vw !important;
}
.header-sidebar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 30%;
}

.question-timer-container {
    height: 54px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #2e2e41;
    background: #2e2e41;
}

.question-timer-container .timer-btn {
    max-width: 140px;
    height: 34px;
    border-radius: 3px;
    border: 1px solid #626262;
    background: #12b6bc;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
}

.question-timer-container .time-countdown {
    color: #f6f7fb;
    margin-left: 10px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.question-timer-container .time-countdown .time-unit {
    font-size: 12px;
    font-weight: 400;
    margin-left: 2px;
}

.question-timer-container .next-ques-time-countdown {
    width: 50px;
    color: #fff;
    margin-left: 10px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.sidebar-question-container {
    height: calc(100% - 55px) !important;
    overflow-y: auto;
    padding: 20px;
    background-color: #191924;
}

.ask-question-div {
    padding: 20px;
    border: 0.5px solid #fff;
    margin-right: 1.5rem;
    overflow-y: auto;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    margin-left: 0;
    width: 40vw;
}
@media (max-width: 768px) {
    .ask-question-div {
        width: calc(100% - 50px);
        margin: 2% auto;
        overflow-y: auto;
        height: 35% !important;
        padding: 10px;
    }
}
.ask-coding-question-div-beam {
    background: #2e2e41;
    border-radius: 14px;
    margin: 10px;
    overflow-y: auto;
    padding: 20px;
    height: calc(100% - 20px);
    width: 35vw;
    position: relative;
}
.ask-question-div-beam {
    background: #2e2e41;
    border-radius: 14px;
    margin: 20px;
    overflow-y: auto;
    padding: 20px;
    height: calc(100% - 70vh);
}
.ask-question-div-beam-code {
    background: #2e2e41;
    margin: 20px;
    overflow-y: auto;
    padding: 20px;
    width: 30%;
    height: calc(100% - 10vh);
}
.alert-interview-popup-content {
    background-color: #13161b;
    max-width: 500px;
    margin: 15px;
}
@media (min-width: 768px) {
    .alert-interview-popup-content {
        margin-top: -28px;
        border: 1px solid #fff;
    }
}
.tawk-branding {
    display: none !important;
}
input[type='number'] {
    -moz-appearance: textfield !important;
}
.question-answer-text-mobile code {
    color: rgba(245, 249, 255, 0.95);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}
.ask-question-div-mobile {
    background: #2e2e41;
    border-radius: 14px;
    margin: 2%;
    overflow-y: auto;
    padding: 2%;
    width: 96%;
    height: 94%;
}
.mobile-common-btn {
    align-items: center;
    background-color: #12b6bc;
    border: 0.5px solid #0000001c;
    border-radius: 5px;
    color: #fff;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    height: 36px;
    justify-content: center;
    line-height: 28px;
    max-width: 155.48px;
    width: 100%;
    min-width: 125px;
}
.mobile-common-btn.disabled {
    background-color: rgba(98, 98, 98, 1) !important;
    border: none !important;
}
.mobile-popup-btn {
    width: 142px;
    height: 41px;
}
.network-check-btn {
    border-radius: '5px';
    border: 1px solid #12b6bc;
    background: #12b6bc;
    background-color: #12b6bc;
    color: rgba(255, 255, 255, 0.98);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
}
.mobile-progress-circle {
    align-items: center;
    border-radius: 50%;
    display: flex;
    font-size: 10px;
    font-style: normal;
    height: 20px;
    justify-content: center;
    letter-spacing: 0.6px;
    line-height: normal;
    position: relative;
    text-align: center;
    width: 20px;
}
@media (min-width: 768px) {
    .alert-interview-popup-content {
        margin-top: -28px;
    }
}
@media (min-width: 991px) {
    .instruction_list_container {
        max-height: 180px;
        overflow-y: auto;
    }
}
@media (max-width: 575px) {
    .main-view-logo {
        width: 85.3px;
        height: 35px;
        object-fit: contain;
        object-position: left center;
    }
}
.mobile-progress-line {
    border: 1px solid #fff !important;
    color: #fff !important;
    margin: 0 !important;
    opacity: 1 !important;
    width: 18px;
}
.mobile-time-info-timer {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.8px;
    text-align: center;
}
.mobile-time-info {
    color: #e9e9e9;
    text-align: center;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
}
.canceled_icon {
    margin-bottom: 30px;
    margin-top: -4rem;
}

.tnc-content-text.mt-0 {
    margin-top: 0;
}
.privacy-policy {
    display: inline;
    text-decoration: none;
}
.position-absolute {
    position: absolute;
}
.code-editor-container {
    width: 98.5%;
    height: 100%;
    background: #2e2e41;
    display: inline-flex;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px;
}

.code-editor-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.language-text {
    color: #fff;
    font-size: 16px;
}

.select-language-container {
    width: 205px;
    height: 35px;
    flex-shrink: 0;
    margin: 0 10px;
    color: #ffffff;
}

.select-language-container > div {
    background: #191924;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    border: none;
    border-radius: 3px;
}

.input-output-container,
.input-container,
.output-container {
    width: 100%;
    height: 100%;
}

.input-output-container {
    margin-left: 20px;
    width: calc(100% - 20px);
}

.code-editor,
.input-container,
.output-container {
    border: 1px solid #000;
    background: #191924;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    padding: 8px 0;
    width: 100%;
}

.output-appears {
    color: #83838a;
}

.input-container {
    resize: none;
}

.input-container,
.output-container {
    padding: 8px;
}

.output-container {
    margin-top: 20px;
    height: calc(100% - 20px);
}
.output-container.sql {
    margin-top: 0px;
    height: 100%;
}

.monaco-editor,
.monaco-editor-background,
.monaco-editor .margin {
    background: inherit !important;
}

.wrapper-4 {
    align-items: center;
    background: #2e2e41ad;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9998;
}

.run-code-btn {
    display: flex;
    height: 34px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    background: #13b1b6;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    width: 105px;
}

.submit-code-btn {
    display: flex;
    height: 34px;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    background: #01b328;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    width: 105px;
    margin-left: 10px;
}

.submit-code-btn:disabled {
    background-color: #9b9b9b;
}

.run-code-btn:disabled {
    background-color: #9b9b9b;
}

.editor-container {
    width: 100%;
    height: 100%;
    display: flex;
}

.btn-icon {
    margin-left: 5px;
}

.react-split__sash--vertical {
    background-color: #000;
    width: 10px !important;
    height: 100% !important;
    border-radius: 2px;
    margin-left: 7.5px;
}

.react-split__sash--vertical .split-sash-content {
    height: 30px;
    content: url('https://incruiter.blob.core.windows.net/frontend/frontend-interview/assets/vertical-drag-icon.svg');
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: e-resize;
}

.split-sash-content-active {
    background-color: transparent !important;
}

.react-split__sash--horizontal {
    height: 10px !important;
    width: 100% !important;
    background-color: #000;
    border-radius: 2px;
    margin-top: 7.5px;
}

.react-split__sash--horizontal .split-sash-content {
    content: url('https://incruiter.blob.core.windows.net/frontend/frontend-interview/assets/horizontal-drag-icon.svg');
    position: absolute;
    left: 50%;
    width: 30px;
    transform: translateX(-50%);
}

.video-tile-container {
    width: 256px;
    height: 160px;
    position: absolute;
    bottom: 25px;
    z-index: 100;
}

.video-mini-tile {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.draggable {
    cursor: move;
    user-select: none;
}

.loader {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    border: 5px solid #000;
    border-top-color: #ffffff;
    box-sizing: border-box;
    background: transparent;
    animation: loading 1s linear infinite;
    margin: auto;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }

    0% {
        transform: rotate(360deg);
    }
}

@media (max-width: 1300px) {
    .question-timer-container .timer-btn {
        font-size: 10px !important;
    }
    .sidebar-container {
        width: 45vw !important;
    }
    .header-sidebar-container {
        width: 31.6%;
    }
}

@media (max-width: 1130px) {
    .sidebar-container {
        width: 50vw !important;
    }
}

@media (max-width: 1000px) {
    .question-timer-container .timer-btn {
        font-size: 8px !important;
    }
}
.settings-btn svg {
    width: 16px !important;
    height: 16px !important;
}
@media (max-width: 767px) {
    .setting-menu {
        padding: 5px;
    }
    .setting-container p {
        font-size: 15px;
    }
    .setting-menu-options svg {
        width: 16px !important;
        height: 16px !important;
    }
    .setting-menu-options p {
        font-size: 13px !important;
    }
}
@media (max-width: 1100px) {
    .preview-page-layout {
        justify-content: center;
        align-items: center;
    }
}
.practice-test {
    color: #12b6bc;
    font-size: 13px;
    font-style: normal;
    font-weight: 800;
    line-height: 35px; /* 269.231% */
    letter-spacing: 1.04px;
    text-decoration-line: underline;
    text-align: center;
    margin: 10px 0px;
}
.practice-test span {
    cursor: pointer;
}
.practice-test-disabled {
    color: #626262;
    font-size: 13px;
    font-style: normal;
    font-weight: 800;
    line-height: 35px; /* 269.231% */
    letter-spacing: 1.04px;
    text-decoration-line: underline;
    text-align: center;
    margin: 10px 0px;
    cursor: default;
}
.practice-vector {
    position: absolute;
    margin-top: 6.5px;
}
.practice-text-title {
    position: absolute;
    top: 0;
    background-color: rgb(0, 200, 83);
    color: rgb(255, 255, 255);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.1px;
}

.mobile-tile {
    left: 0;
}
.code-block {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    padding: 5px 12px;
    background: #2e2e41;
    width: 100%;
    white-space: pre-wrap;
}
.code-block.mobile {
    background: #191924;
}

.code-block-div {
    background-color: #cadff5;
    width: 90%;
    margin-left: 10px;
}
.code-content-pre-code-editor {
    white-space: pre-wrap; /* Allows the text to wrap */
    overflow: hidden; /* Hides any overflow (optional, based on your needs) */
    word-wrap: break-word; /* Breaks long words */
}
.code-content-pre-code-editor code {
    display: block; /* Make sure the code takes full width */
}
.flip-countdown-card .card__top,
.flip-countdown-card .card__bottom {
    background: #12b6bc !important;
    border-radius: 7px !important;
    color: #fff !important;
}
.flip-countdown-title {
    color: #f2f2f2;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    text-transform: uppercase;
}
.early-join .center-img-div {
    margin: 20px 0px;
}
.early-time-div-wrapper {
    margin: 10px 0px;
}
@media (max-width: 767px) {
    .flip-countdown-title {
        font-size: 10px !important;
    }
    .early-join img {
        width: 80px;
        height: 80px;
    }
}
.flip-countdown-card .card__top,
.flip-countdown-card .card__bottom,
.flip-countdown-card .card__back {
    width: 8vw;
}

.green-color {
    color: #1ecd00;
}

.red-color {
    color: #ff0000;
}

.orange-color {
    color: #fb6340;
}
.audio-video-check-sub-container-2 video {
    max-height: 280px !important;
}

.options-container {
    background-color: #161621;
    padding: 20px;
    border-radius: 8px;
    color: #e2e2e2;
    font-family: Arial, sans-serif;
}

.options-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    margin-bottom: 15px;
}

.clear-response {
    background: none;
    border: none;
    color: #b1b1b1;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.clear-icon {
    font-size: 18px;
    margin-right: 4px;
}

.options-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.option-item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px;
    background-color: #1e1e2a;
    border: 1px solid #444;
    border-radius: 6px;
    cursor: pointer;
}

.radio-button {
    width: 20px;
    height: 20px;
    border: 2px solid #888;
    border-radius: 50%;
    display: inline-block;
    position: relative;
}

.radio-button.selected {
    border-color: #3a82f6;
    background-color: #3a82f6;
}

.radio-button.selected::after {
    content: '';
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.option-label {
    font-size: 16px;
    color: #e2e2e2;
}

input[type='radio'] {
    display: none;
}

.dark-bg {
    background: #191924;
}

.mcq-question-container {
    color: #fff;
    font-size: 14px;
    padding: 20px;
    display: block;
}

.gap-05 {
    gap: 5px;
}

.fullscreen-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.countdown-text {
    color: rgba(245, 249, 255, 0.95);
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: 90px; /* 180% */
}

.countdown-body {
    color: rgba(245, 249, 255, 0.95);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.15px;
}

.timer-count-body {
    height: 360px;
    width: 502px;
}

.header-line {
    border-bottom: 1px solid #5e6267;
}

.countdown-buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
    font-size: 12px !important;
}

.primary-modal-btn {
    background: #ec2d38 !important;
    font-size: 12px !important;
    border: none !important;
    height: 36px !important;
}

.modal-btn {
    font-size: 12px !important;
    border: none !important;
    height: 36px !important;
}

.screen-share-button {
    display: flex;
    height: 30px;
    padding: 2px 5px !important;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    background: #12b6bc;
    font-size: 12px;
    color: white;
}

.faqHeader {
    color: rgba(245, 249, 255, 0.95);
    text-align: center;
    font-size: 22px;
    line-height: 27px;
}

.screen-qs-container {
    height: calc(100% - 50px);
    overflow-y: auto;
}

.question-container .pc-total {
    font-size: 14px;
    margin-bottom: 10px;
    color: #204984;
}

.pc-progress-new-bar {
    margin: 10px 0px;
    height: 15px;
    display: flex;
    align-items: center;
    padding: 0;
    background-color: #c5c5c5;
}
.pc-progress-bar {
    background-image: linear-gradient(to right, #cadff5, #133f7d);
    height: 100%;
}

.pc-progress-bar {
    height: 100%;
    margin: 0;
}
/* .progress-bar {
    background-color: #e0e0e0;
    border-radius: 3px;
    height: 6px;
    margin-top: 10px;
    margin-bottom: 10px;
} */
.instruction-subtitle-error {
    color: #f00;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.scaled-container {
    transform: scale(0.9);
    transform-origin: center;
}

.oval-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* So clicks pass through */
    z-index: 1000;
}

.h-40 {
    height: 40% !important;
}

.width-setting {
    max-width: 680px;
    min-width: 600px;
}
